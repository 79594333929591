@keyframes rect1 {
    from {width: 448px;}
    to {width: 480px;}
}

@keyframes rect2 {
    from {width: 448px;}
    to {width: 480px;}
}

@keyframes rect3 {
    from {width: 448px;}
    to {width: 480px;}
}

@keyframes rect4 {
    from {width: 448px;}
    to {width: 480px;}
}

@keyframes rect5 {
    from {width: 448px;}
    to {width: 480px;}
}