@font-face {
  font-family: Oswald;
  src: url("./fonts/Oswald/Oswald-VariableFont_wght.ttf"), url("./fonts/Oswald/static/Oswald-Bold.ttf"), url("./fonts/Oswald/static/Oswald-ExtraLight.ttf"), url("./fonts/Oswald/static/Oswald-Light.ttf"), url("./fonts/Oswald/static/Oswald-Medium.ttf"), url("./fonts/Oswald/static/Oswald-Regular.ttf"), url("./fonts/Oswald/static/Oswald-SemiBold.ttf");
}

@font-face {
  font-family: Glacial_Indifference;
  src: url("./fonts/Glacial_Indifference/GlacialIndifference-Bold.otf"), url("./fonts/Glacial_Indifference/GlacialIndifference-Italic.otf"), url("./fonts/Glacial_Indifference/GlacialIndifference-Regular.otf");
}

@font-face {
  font-family: League_Spartan;
  src: url("./fonts/League_Spartan/static/LeagueSpartan-Black.ttf"), url("./fonts/League_Spartan/static/LeagueSpartan-Bold.ttf"), url("./fonts/League_Spartan/static/LeagueSpartan-ExtraBold.ttf"), url("./fonts/League_Spartan/static/LeagueSpartan-ExtraLight.ttf"), url("./fonts/League_Spartan/static/LeagueSpartan-Light.ttf"), url("./fonts/League_Spartan/static/LeagueSpartan-Medium.ttf"), url("./fonts/League_Spartan/static/LeagueSpartan-Regular.ttf"), url("./fonts/League_Spartan/static/LeagueSpartan-SemiBold.ttf"), url("./fonts/League_Spartan/static/LeagueSpartan-Thin.ttf");
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
}

.container-fluid {
  padding: 0 !important;
  margin: 0 !important;
}