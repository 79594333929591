.fondo8{
    background: linear-gradient(to right, #DDAB65, #D4145A);
    width: 100%;
    height: 100vh;
    position: relative;
    z-index: 1;
}

.libro8 {
    position: relative;
    width: 21.8vw;
    height: 26.5vw;
    transform: rotate(10deg);
}

.btn8 {
    position: relative;
    background: #1630BE !important;
    font-weight: bolder !important;
    z-index: 3;
}

.title8-1 {
    font-family: League_Spartan;
    font-weight: 400;
    color: #ffdd00;
    text-shadow: 2px 2px #555;
    font-size: 3.9vw;
    position: relative;
    padding-top: 8vh;
}

.text8-1{
    font-family: Glacial_Indifference;
    font-weight: 400;
    color: rgb(255, 255, 255);
    text-shadow: 1px 1px #000000;
    font-size: 1.7vw;
    position: relative;
    text-align: justify;
    word-spacing: 7px;
}

.container-text8 {
    position: relative;
    height: 100%;
    text-align: center;
    margin: 0 !important;
    align-content: center;
}

.buy-book {
    text-decoration: none;
}

.custom-shape-divider-bottom-section8 {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
    transform: rotate(180deg);
}

.custom-shape-divider-bottom-section8 svg {
    position: relative;
    display: block;
    width: calc(100% + 1.3px);
    height: 150px;
}

.custom-shape-divider-bottom-section8 .shape-fill {
    fill: #FFFFFF;
}

@media screen and (max-width: 1200px) {
    .title8-1 {
        font-size: 4vh;
    }

    .text8-1 {
        font-size: 2vh;
    } 
    .libro8 {
        display: none;
    }
}

@media screen and (min-width: 1020px) and (max-width: 1200px) {
    .libro8 {
        display: inline;
    }
}

@media screen and (max-height: 546px) {
    .title8-1 {
        font-size: 4vw;
    }
    .text8-1 {
        font-size: 2vw;
    }
}

@media screen and (min-width: 1400px) {
    .title8-1 {
        font-size: 3vw;
    }
    .text8-1 {
        font-size: 1.4vw;
    }
}

@keyframes move {
    100% {
        transform: translate3d(0, 0, 1px) rotate(360deg);
    }
}

@keyframes animate {
    0%{
        transform: translateY(0) rotate(0deg);
        opacity: 1;
        border-radius: 0;
    }
    100%{
        transform: translateY(-1000px) rotate(720deg);
        opacity: 0;
        border-radius: 50%;
    }
}

.background {
    position: absolute;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    margin: 0;
    padding: 0;
    overflow: hidden;
}

.background li {
    position: absolute;
    display: block;
    list-style: none;
    width: 20px;
    height: 20px;
    background: rgba(255, 255, 255, 0.2);
    animation: animate 19s linear infinite;
}

.background li:nth-child(0) {
    left: 86%;
    width: 139px;
    height: 139px;
    bottom: -139px;
    animation-delay: 1s;
}
.background li:nth-child(1) {
    left: 11%;
    width: 163px;
    height: 163px;
    bottom: -163px;
    animation-delay: 4s;
}
.background li:nth-child(2) {
    left: 5%;
    width: 162px;
    height: 162px;
    bottom: -162px;
    animation-delay: 2s;
}
.background li:nth-child(3) {
    left: 20%;
    width: 221px;
    height: 221px;
    bottom: -221px;
    animation-delay: 1s;
}
.background li:nth-child(4) {
    left: 37%;
    width: 240px;
    height: 240px;
    bottom: -240px;
    animation-delay: 1s;
}
.background li:nth-child(5) {
    left: 55%;
    width: 289px;
    height: 289px;
    bottom: -289px;
    animation-delay: 3s;
}
.background li:nth-child(6) {
    left: 75%;
    width: 199px;
    height: 199px;
    bottom: -199px;
    animation-delay: 5s;
}
.background li:nth-child(7) {
    left: 5%;
    width: 193px;
    height: 193px;
    bottom: -193px;
    animation-delay: 10s;
}
.background li:nth-child(8) {
    left: 5%;
    width: 267px;
    height: 267px;
    bottom: -267px;
    animation-delay: 15s;
}
.background li:nth-child(9) {
    left: 35%;
    width: 275px;
    height: 275px;
    bottom: -275px;
    animation-delay: 40s;
}