.fondo2{
  background: #fff;
  width: 100%;
  height: 100vh;
  position: relative;
}

.container-title2 {
  position: relative;
  align-content: space-around;
  width: 100vw;
  height: 10vh;
}

.prueba{
  display: flex;
  width: 100vw;
  justify-content: space-around;
}

.img-prueba {
  position: relative;
  opacity: 1;
  width: 100%;
  max-height: 50vh;
  padding: 1vw;
  border-radius: 70px;
}

.texto2 {
  font-family: Glacial_Indifference;
  color: #D4145A;
  font-size: 1.7vw;
  text-align: justify;
  text-shadow: #000 1px 1px;
  word-spacing: 0.5px;
  position: relative;
  border: 5px solid #D4145A;
  padding: 2vh;
  margin: 1vh;
  border-radius: 10px;
  background-color: #0000000e;
  font-style: oblique;
  box-shadow: 6px 6px 12px rgba(27, 27, 27, 0.701);
}

.texto2:hover{
  box-shadow: 2px 2px 8px black;
}

.title2 {
  font-family: League_Spartan;
  color: #D4145A;
  font-size: 3.9vw;
  text-align: center;
  text-shadow: #000 1px 1px;
  position: relative;
  margin-top: 20vh;
}

.text-align-center {
  text-align: center;
}

.linea-horizontal2 {
  color: #D4145A;
  width: 80%;
  display: none;
}

.linea-horizontal2 > hr {
  border-width: 1.5vh;
}

@media screen and (max-width: 1200px) {
  .texto2 {
    font-size: 2vh;
    margin-left: 30px;
    margin-right: 30px;
  }
  
  .title2 {
    font-size: 5vh;
    margin-top: 5vh;
  }

  .prueba {
    align-items: center;
  }

  .img-prueba {
    padding: 0;
  }

  .linea-horizontal2 {
    display: inline-block;
  }
}

@media screen and (min-width: 1020px) and (max-width: 1200px) {
  .texto2 {
    font-size: 3vh;
  }

  .img-prueba {
    max-width: 44vw;
  }
}

@media screen and (min-width: 1400px) {
  .img-prueba {
    width: 80%;
  }
}

@media screen and (max-height: 546px) {
  .img-prueba {
    width: 30%;
    max-height: 25vw;
    margin-top: 8vh;
  }
  .texto2 {
    font-size: 1.8vw;
  }
  .title2 {
    font-size: 5vw;
    margin-top: 7vh;
  }
  .linea-horizontal2 {
    display: none;
  }
}

.container-text2 {
  height: 90vh;
  margin: 0 !important;
  align-content: space-around;
  align-items: center;
}