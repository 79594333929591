.texto3 {
    font-family: Glacial_Indifference;
    color: #DDAB65;
    font-size: 1.7vw;
    text-align: justify;
    text-shadow: #000 1px 1px;
    word-spacing: 0.5px;
    position: relative;
    border: 5px solid #DDAB65;
    padding: 2vh;
    margin: 1vh;
    border-radius: 10px;
    background-color: #0000000e;
    font-style: oblique;
    box-shadow: 6px 6px 12px rgba(27, 27, 27, 0.701);
}

.texto3:hover{
  box-shadow: 2px 2px 8px black;
}

.title3 {
    font-family: League_Spartan;
    color: #DDAB65;
    font-size: 3.9vw;
    text-align: center;
    text-shadow: #000 1px 1px;
    position: relative;
}

.linea-horizontal3 {
  color: #DDAB65;
  width: 80%;
  display: none;
}

.linea-horizontal3 > hr {
  border-width: 1.5vh;
}

@media screen and (max-width: 1200px) {
    .texto3 {
      font-size: 2vh;
      margin-left: 30px;
      margin-right: 30px;
    }
    
    .title3 {
      font-size: 4.5vh;
      margin-top: 3vh;
    }

    .linea-horizontal3 {
      display: inline-block;
    }
}

@media screen and (min-width: 1020px) and (max-width: 1200px) {
    .texto3 {
      font-size: 3vh;
    }
}

@media screen and (max-height: 546px) {
  .img-prueba {
    width: 30%;
    max-height: 25vw;
    margin-top: 8vh;
  }
  .texto3 {
    font-size: 1.8vw;
  }
  .title3 {
    font-size: 5vw;
    margin-top: 7vh;
  }
  .linea-horizontal3 {
    display: none;
  }
}