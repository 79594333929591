@import "../css/animations/animation7.css";

.fondo7{
    background: #fff;
    width: 100%;
    height: 100vh;
    position: relative;
    display: flex;
    justify-content: center;
}

.title7-1{
    font-family: League_Spartan;
    font-weight: 400;
    color: #DDAB65;
    text-shadow: 2px 2px #555;
    font-size: 3.9vw;
    position: relative;
    text-align: center;
    padding-top: 3vh;
}

.title7-2{
    font-family: League_Spartan;
    font-weight: 400;
    color: #D4145A;
    text-shadow: 2px 2px #555;
    font-size: 3.9vw;
    position: relative;
}

.rect1 {
    position: relative;
    width: 40vw;
    height: 8vh;
    border: 3px solid rgba(85, 85, 85, 0);
    background: #D4145A;
    transition: width 2s;
    box-shadow: 5px 5px 8px #555;
    margin-bottom: 20px;
}

.rect1:hover{
    width: 30vw;
}

.text7-1{
    font-family: Glacial_Indifference;
    font-weight: 400;
    color: #fff;
    -webkit-text-stroke: 0.2px black;
    font-size: 36px;
    position: relative;
    text-align: center;
    padding: 0;
}

.container-text7 {
    position: relative;
    height: 100vh;
    text-align: center;
    margin: 0 !important;
}

.container7-text2 {
    align-items: center;
}

.foto7-1 {
    height: 35vh;
    border: 1px solid black;
    border-radius: 50px;
    box-shadow: 0px 0px 30px black;
}

@media screen and (max-width: 1200px) {

    .title7-1 {
        font-size: 5vh;
        margin-top: 10vh;
    }

    .title7-2 {
        font-size: 4vh;
    }

    .container-title7-2 {
        justify-content: center;
    }

    .rect1 {
        width: 90vw;
    }

    .rect1:hover{
        width: 70vw;
    }
    
    .text7-1 {
        font-size: 4vh;
    }

    .foto7-1 {
        display: none;
    }

    .container-text7 {
        align-content: center;
    }
}

.ribbon {
    width: 100%;
    height: 30vh;
    background: linear-gradient(130deg, #D4145A 20%, transparent 60%);
    background-size: 100vw 30vw;
    position: absolute;
}

@media screen and (max-width: 1200px) {
    .ribbon {
        display: none;
    }
}