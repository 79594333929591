.fondo6{
    background: #fff;
    width: 100%;
    height: 100vh;
    position: relative;
}

.title6{
    font-family: League_Spartan;
    font-weight: 400;
    color: #D4145A;
    text-shadow: 2px 2px #000000;
    font-size: 3.9vw;
    position: relative;
    margin: 4vh 0;
    text-align: center;
}

.container-son6 {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 4vh 0;
    z-index: 1;
}

.img6a{
    border: 0px solid black;
    border-radius: 17px;
    box-shadow: 2px 2px 8px black;
    position: relative;
    background-color: #000000;
}

.img6a:hover{
    box-shadow: 6px 6px 12px rgba(27, 27, 27, 0.701) ;
}

.texto6{
    font-family: Glacial_Indifference;
    font-weight: 400;
    color: #000;
    text-shadow: 1px 1px 8px #DDAB65;
    font-size: 1.7vw;
    position: absolute;
    text-align: center;
    margin: 0 !important;
    width: 50%;
}

.container-text6 {
    height: 95vh;
    margin: 0 !important;
    align-items: center;
    align-content: center;
}

@media screen and (max-width: 1200px) {
    .title6 {
        font-size: 5vh;
        margin-top: 30vh;
    }
    .img6a {
        max-width: 40vw;
    }
    .texto6 {
        font-size: 1.2vh;
        width: 40%;
    }
}

@media screen and (min-width: 2000px) {
    .texto6 {
        font-size: 2vh;
        width: 40%;
    }
}
  
@media screen and (min-width: 1020px) and (max-width: 1200px) {
    .img6a {
        max-width: 15vw;
    }
    .texto6 {
        font-size: 0.8vw;
        width: 10%;
    }
}

@media screen and (max-height: 546px) {
    .img6a {
        max-width: 25vw;
    }
    .container-son6 {
        width: 33.33% !important;
    }
    .texto6 {
        font-size: 2vw;
        width: 70%;
    }
    .title6 {
        font-size: 5vw;
        margin-bottom: 5vh;
        margin-top: 5vh;
    }
}