.fondo11{
    background: linear-gradient(to left, #DDAB65, #D4145A);
    width: 100%;
    height: 100vh;
    position: relative;
}

.text11-1{
    font-family: League_Spartan;
    font-weight: 400;
    color: rgb(255, 255, 255);
    text-shadow: 2px 2px #555;
    font-size: 3.9vw;
    position: relative;
    text-align: center;
}

.container-text11-1 {
    align-items: center;
    display: flex;
    justify-content: center;
}

.book{
    position: relative;
    width: 20vw;
    height: 50vh;
    transform: rotate(5deg);
}

.btn11{
    position: relative;
    background: #1630BE !important;
    font-weight: bolder !important;
}

.container-btn11 {
    align-items: center;
    display: flex;
    justify-content: center
}

.logo11{
    filter: brightness(12);
    position: relative;
    width: 20vw;
    height: 15vh;
}

.rrss11{
    position: relative;
    height: 13vh;
}

.container-logo11 {
    display: flex;
    align-items: center;
    flex-direction: column;
}

.container-text11 {
    position: relative;
    height: 100%;
    text-align: center;
    margin: 0 !important;
}

.container11-fila1 {
    height: 50vh;
}

.container11-fila3 {
    height: 25vh;
    align-items: center;
}

.custom-shape-divider-top-section11 {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
}

.custom-shape-divider-top-section11 svg {
    position: relative;
    display: block;
    width: calc(100% + 1.3px);
    height: 220px;
}

.custom-shape-divider-top-section11 .shape-fill {
    fill: #FFFFFF;
}

.align-items {
    align-items: center;
    align-content: center;
    height: 25vh;
}

@media screen and (max-width: 1200px) {
    .text11-1{
        font-size: 4.5vh;
    }
    .btn11{
        font-size: 2vh !important;
        height: 10vh;
    }

    .container-logo11 {
        flex-direction: column;
        height: 15vh;
    }
    
    .logo11{
        max-width: 40vw;
        width: auto;
        height: 12vh;
    }

    .book{
        height: 20vh;
        width: 30vw;
        display: none;
    }

    .rrss11{
        max-width: 70vw;
    }
}

@media screen and (min-width: 1020px) and (max-width: 1200px) {
    .book {
        padding-top: 5vh;
        height: 30vh;
        width: 15vw;
    }
}

@media screen and (max-height: 546px) {
    .btn11 {
        padding: 3vw !important;
    }
}