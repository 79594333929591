.fondo{
    background: linear-gradient(to right, #DDAB65, #D4145A);
    width: 100%;
    height: 100vh;
    position: relative;
}

.canc{
    font-family: League_Spartan;
    color: #ffdd00;
    text-shadow: 2px 2px #000;
    font-size: 8vw;
    position: relative;
  }

.diag{
    font-family: Glacial_Indifference;
    font-weight: 400;
    color: #fff;
    font-size: 3vw;
    position: relative;
    text-align: center;
}

.logo{
    position: relative;
    max-width: 85vw;
    max-height: 50vh;
}

.libro{
    position: relative;
    width: 80%;
    transform: rotate(10deg);
    max-width: 20vw;
    max-height: 50vh;
}

.container-libro {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
    z-index: 1;
}

.container-text {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding-top: 4vw;
}

.container-logo {
    display: flex;
    justify-content: center;
    align-items: flex-end;
}

.title1-container {
    height: 45vh;
    margin: 0 !important;
}

.elements1-container {
    align-items: center;
    height: 45vh;
    margin: 0 !important;
}

.container1-button {
    display: flex;
    justify-content: center;
}

.btn1 {
    background: #1630BE !important;
    font-weight: bolder !important;
    width: 20vw;
    z-index: 3;
}

@media screen and (max-width: 1200px) {
    .container-text {
        height: 30vh;
        justify-content: center;
    }
    .container-logo {
        height: 30vh;
        align-items: center;
    }
    .container-libro {
        height: 40vh;
    }

    .canc{
        font-size: 8vh;
    }
    
    .diag{
        font-size: 3vh;
        width: 60vw;
    }
    .libro {
        max-width: 40vw;
        max-height: 40vh;
    }

    .btn1 {
        width: auto;
    }
}

@media screen and (max-height: 546px) {
    .libro {
        max-width: 20vw;
    }
}

@media screen and (min-width: 1020px) and (max-width: 1200px) {
    .container-text {
        flex-direction: row;
        justify-content: space-between;
    }

    .container-logo {
        align-items: flex-end;
    }
    
    .libro {
        max-width: 25vw;
    }
    
    .canc {
        padding-left: 20px;
        color: #D4145A;
    }

    .diag{
        padding-right: 20px;
    }
}

.custom-shape-divider-bottom-section1 {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
    transform: rotate(180deg);
}

.custom-shape-divider-bottom-section1 svg {
    position: relative;
    display: block;
    width: calc(100% + 1.3px);
    height: 150px;
}

.custom-shape-divider-bottom-section1 .shape-fill {
    fill: #FFFFFF;
}