.fondo10{
    background: #fff;
    width: 100%;
    height: 100vh;
    position: relative;
}
 
.logo10{
    position: relative;
    width: 20vw;
    height: 15vh;
}

.title10-1 {
    font-family: League_Spartan;
    font-weight: 400;
    color: #D4145A;
    text-shadow: 2px 2px #555;
    font-size: 3.9vw;
    position: relative;
    padding-top: 3vh;
}

.text10-1 {
    font-family: Glacial_Indifference;
    font-weight: 400;
    color: #DDAB65;
    font-size: 1.7vw;
    position: relative;
    text-align: justify;
    word-spacing: 4px;
    text-shadow: #000 1px 1px;
    position: relative;
    border: 5px solid #DDAB65;
    padding: 2vh;
    margin: 1vh;
    border-radius: 10px;
    background-color: #0000000e;
    box-shadow: 6px 6px 12px rgba(27, 27, 27, 0.701);
}

.container-text10 {
    position: relative;
    height: 100%;
    text-align: center;
    margin: 0 !important;
    align-items: center;
}


.foto10-1 {
    height: 35vh;
    border: 1px solid black;
    border-radius: 50px;
    box-shadow: 0px 0px 30px black;
}

.container-10-img-text {
    display: flex;
    align-items: center;
    margin-top: 2vh !important;
}

.container10-img-1 {
    margin-bottom: 2vh !important;
}

@media screen and (max-width: 1200px) {
    .title10-1 {
        font-size: 6vh;
        margin-top: 5vh !important;
    }

    .text10-1 {
        font-size: 2vh;
    }

    .logo10 {
        width: 30vw;
        height: 6vh;
    }
}

@media screen and (min-width: 1020px) and (max-width: 1200px) {
    .text10-1 {
        font-size: 3vh;
    }

    .logo10 {
        width: 20vw;
        height: 10vh;
    }
}

@media screen and (max-height: 546px) {
    .container10-logo{
        height: auto;
        margin-top: 2vh !important;
    }
    .logo10 {
        width: 15vw;
        height: 15vh;
    }
    .text10-1 {
        font-size: 1.5vw;
    }
}

.ribbon-section10 {
    position: absolute;
    top: 0;
    left: 0;
    width: 10vw;
    height: 20vh;
    background-color: #DDAB65;
    color: #fff;
    border: 1px solid #DDAB65;
    border-radius: 0px 50px 50px 0px;
    transition: width 1s;
    z-index: 1;
}

.ribbon-section10-2 {
    position: absolute;
    top: 0;
    right: 0;
    width: 10vw;
    height: 20vh;
    background-color: #DDAB65;
    color: #fff;
    border: 1px solid #DDAB65;
    border-radius: 50px 0px 0px 50px;
    transition: width 1s;
}

.ribbon-section10:hover, .ribbon-section10-2:hover {
    width: 15vw;
}