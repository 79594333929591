.fondo4{
    background: linear-gradient(to left, #DDAB65, #D4145A);
    width: 100%;
    height: 100vh;
    position: relative;
}

.title4{
    font-family: League_Spartan;
    font-weight: 400;
    color: rgb(243, 174, 76);
    text-shadow: 2px 2px #000000;
    font-size: 3.9vw;
    text-align: center;
    position: relative;
    margin-top: 2vh;
}

.title4-1{
    font-family: Glacial_Indifference;
    font-weight: 400;
    text-align: center;
    color: rgb(255, 255, 255);
    font-size: 3.7vw;
    text-shadow: 2px 2px #000000;
}

.texto-rosa {
    color: #D4145A;
}

.texto4{
    text-align: center;
    font-family: Glacial_Indifference;
    font-weight: 400;
    color: rgb(255, 255, 255);
    text-shadow: 2px 2px #000000;
    font-size: 1.7vw;
    position: relative;
}

.container-text4 {
    height: 100vh;
    margin: 0 !important;
    align-content: space-around;
    align-items: center;
    align-content: center;
}

.foto4-1 {
    height: 35vh;
    border: 1px solid white;
    border-radius: 50px;
    box-shadow: 0px 0px 200px white;
}

.container4-text-img-1 {
    display: flex;
    justify-content: center;
}

.container4-img-1 {
    display: flex;
    justify-content: center;
}

@media screen and (max-width: 1200px) {
    .title4 {
        font-size: 6vh;
        margin-top: 8vh;
    }

    .title4-1 {
        font-size: 4vh;
    }

    .texto4 {
        font-size: 2vh;
    }

    .container-text4 {
        align-content: center;
    }

    .foto4-1 {
        display: none;
    }

    .container4-img-1 {
        margin: 0 !important;
        padding: 0 !important;
        height: 0;
        width: 0;
    }
}

@media screen and (max-height: 546px) {
    .title4 {
        font-size: 4vw;
    }

    .title4-1 {
        font-size: 2.4vw;
    }

    .texto4 {
        font-size: 1.4vw;
    }
    .container4-text-img-1 {
        width: 50% !important;
    }
    .container4-img-1 {
        width: 0 !important;
    }
    .container4-text {
        width: 50% !important;
    }
}

.custom-shape-divider-top-section4 {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
}

.custom-shape-divider-top-section4 svg {
    position: relative;
    display: block;
    width: calc(166% + 1.3px);
    height: 102px;
    transform: rotateY(180deg);
}

.custom-shape-divider-top-section4 .shape-fill {
    fill: #FFFFFF;
}

.custom-shape-divider-bottom-section4 {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
    transform: rotate(180deg);
}

.custom-shape-divider-bottom-section4 svg {
    position: relative;
    display: block;
    width: calc(100% + 1.3px);
    height: 50px;
}

.custom-shape-divider-bottom-section4 .shape-fill {
    fill: #FFFFFF;
}