.fondo5{
    background: linear-gradient(to left, #DDAB65, #D4145A);
    width: 100%;
    height: 100vh;
    position: relative;
}

.title5{
    font-family: League_Spartan;
    font-weight: 400;
    color: rgb(255, 255, 255);
    text-shadow: 2px 2px #555;
    font-size: 3.9vw;
    position: relative;
    text-align: center;
    padding-left: 0.8vw;
}

.title5:hover{
    color: rgb(243, 174, 76);
}

.texto5{
    font-family: Glacial_Indifference;
    font-weight: 400;
    color: rgb(255, 255, 255);
    text-shadow: 2px 2px #555;
    font-size: 1.5vw;
    text-align: justify;
    position: relative;
    text-align: justify;
    padding-top: 5vh;
    padding-left: 1.6vw;
}

.libro5{
    position: relative;
    transform: rotate(1deg);
    max-width: 40vw;
    max-height: 70vh;
    z-index: 2;
}

.quimio{
    position: relative;
    font-family: League_Spartan;
    font-weight: 700;
    color: rgb(255, 255, 255);
    text-shadow: 2px 2px #555;
    font-size: 2vw;
    text-align: center;
    padding-top: 5vh;
}

.horizontal5 {
    margin: 4px;
}

.quimio:hover{
    color: rgb(243, 174, 76);
}

.texto5-1{
    font-family: Glacial_Indifference;
    font-weight: 400;
    color: rgb(255, 255, 255);
    text-shadow: 2px 2px #555;
    font-size: 1.5vw;
    position: relative;
    text-align: center;
    padding-top: 5vh;
    padding-left: 1.6vw;
}

.container-text5 {
    height: 100vh;
    margin: 0 !important;
    align-content: space-around;
    align-items: center;
}

.container-img-libro5 {
    display: flex;
    justify-content: center;
    align-items: center
}

@media screen and (max-width: 1200px) {

    .quimio {
        font-size: 2.5vh;
        padding-top: 2vh;
    }
    .title5 {
        font-size: 2.5vh;
        padding-top: 3vh;
    }
    .texto5, .texto5-1 {
        font-size: 2vh;
        padding-right: 20px;
        padding-left: 20px;
        padding-top: 2vh;
    }
}

@media screen and (min-width: 1020px) and (max-width: 1200px) {
    .title5 {
        font-size: 3vh;
        padding-top: 12vh;
    }
}

@media screen and (max-height: 546px) {
    .libro5 {
        max-height: 30vh;
    }
    .title5 {
        font-size: 2.5vw;
        padding-top: 8vh;
    }
    .quimio {
        font-size: 2vw;
    }
    .texto5 {
        font-size: 1.5vw;
    }
    .texto5-1 {
        font-size: 1.5vw;
    }
}

.custom-shape-divider-top-section5 {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
}

.custom-shape-divider-top-section5 svg {
    position: relative;
    display: block;
    width: calc(200% + 1.3px);
    height: 80px;
}

.custom-shape-divider-top-section5 .shape-fill {
    fill: #FFFFFF;
}

.custom-shape-divider-bottom-section5 {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
    transform: rotate(180deg);
}

.custom-shape-divider-bottom-section5 svg {
    position: relative;
    display: block;
    width: calc(201% + 1.3px);
    height: 101px;
}

.custom-shape-divider-bottom-section5 .shape-fill {
    fill: #FFFFFF;
}