.fondo9{
    background: #fff;
    width: 100%;
    height: 100vh;
    position: relative;
}

.title9-1{
    font-family: League_Spartan;
    font-weight: 400;
    color: #DDAB65;
    text-shadow: 2px 2px #555;
    font-size: 3.9vw;
    position: relative;
    padding-top: 5vh;
}

.text9-1{
    font-family: Glacial_Indifference;
    font-weight: 400;
    color: #D4145A;
    text-shadow: 1px 1px #000000;
    font-size: 1.7vw;
    position: relative;
    text-align: justify;
    word-spacing: 4px;
    border: 5px solid #D4145A;
    padding: 2vh;
    margin: 1vh;
    border-radius: 10px;
    background-color: #0000000e;
    font-style: oblique;
    box-shadow: 6px 6px 12px rgba(27, 27, 27, 0.701);
}

.rrss{
    position: relative;
    max-height: 13vh;
    background: #D4145A;
    border: 5px solid #D4145A;
    padding: 2vh;
    margin: 1vh;
    border-radius: 10px;
    box-shadow: 6px 6px 12px rgba(27, 27, 27, 0.701);
    z-index: 1;
}

.container-text9 {
    position: relative;
    height: 100%;
    text-align: center;
    margin: 0 !important;
    align-content: space-around;
}

@media screen and (max-width: 1200px) {
    .title9-1 {
        font-size: 5vh;
    }

    .text9-1 {
        font-size: 2vh;
    }

    .ribbon-section9, .ribbon-section9-2 {
        display: none;
    }
}

@media screen and (min-width: 1020px) and (max-width: 1200px) {
    .text9-1 {
        font-size: 3.5vh;
    }
}

@media screen and (max-height: 546px) {
    .title9-1 {
        font-size: 4vw;
    }
    .text9-1 {
        font-size: 1.5vw;
    }
}

.ribbon-section9 {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 10vw;
    height: 20vh;
    background-color: #DDAB65;
    color: #fff;
    border: 1px solid #DDAB65;
    border-radius: 0px 50px 50px 0px;
    transition: width 1s;
    z-index: 1;
}

.ribbon-section9-2 {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 10vw;
    height: 20vh;
    background-color: #DDAB65;
    color: #fff;
    border: 1px solid #DDAB65;
    border-radius: 50px 0px 0px 50px;
    transition: width 1s;
}

.ribbon-section9:hover, .ribbon-section9-2:hover {
    width: 15vw;
}