body{
  padding: 0;
  overflow: visible;
  overflow-x: hidden;
}

* {box-sizing: border-box}

::-webkit-scrollbar {
  width: 12px;
  height: 12px;
}

::-webkit-scrollbar-thumb {
  background-color: #fdc7d5;
  border: 2px solid #c0587d;
  border-radius: 25px;
}

::-webkit-scrollbar-track {
  background-color: #c0587d;
}